<template>
  <div class="border-b border-gray-800 px-6 py-4">
    <div v-if="loading" class="flex flex-col">
      <BaseLoader class="m-auto h-5 w-5" />
    </div>
    <div v-else>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-2">
          <span class="block text-sm font-semibold text-gray-200">Choose a Publisher to set alerts on</span>
          <span v-if="required.alertType" class="block text-xs text-red-400">Required</span>
          <span v-else-if="duplicateAlert" class="block text-xs text-red-400">
            Alert with same publisher already exists
          </span>
        </div>
        <BaseSelect
          button-width-class="w-full"
          dropdown-width-class="w-full"
          :options="publishers"
          v-model="alertData.research_publisher_id"
          :searchable="true"
          :placeholder="'Select Publisher'"
          id="publisher-dropdown"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';

const emitter = useEmitter();
const $http = useHttp();
const props = defineProps({
  alert: { type: Object, default: () => null }
});

const loading = ref(true);
const publishers = ref([]);
const required = ref({ alertType: false });
const alertData = ref({ research_publisher_id: null });
const duplicateAlert = ref(false);

function showValidationError(data) {
  if (data.alertType) {
    required.value.alertType = data.alertType;
    setTimeout(() => {
      required.value.alertType = false;
    }, 3000);
  } else if (data.duplicateAlert) {
    duplicateAlert.value = data.duplicateAlert;
    setTimeout(() => {
      duplicateAlert.value = false;
    }, 5000);
  }
}
function editData() {
  if (props.alert) {
    alertData.value = props.alert.config;
  }
}
async function fetchPublishers() {
  const response = await $http.get('/research_publishers');
  publishers.value = response.data.publishers
    .map(item => {
      return {
        id: item.id,
        label: item.name
      };
    })
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  loading.value = false;
}
watch(
  alertData,
  () => {
    emitter.$emit('config:alert', { config: alertData.value });
  },
  { deep: true, immediate: true }
);
onMounted(() => {
  emitter.$on('ResearchArticleForm', data => {
    showValidationError(data);
  });
  editData();
  fetchPublishers();
});
onBeforeUnmount(() => {
  emitter.$off('ResearchArticleForm');
});
</script>
